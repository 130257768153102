<script>
import { doc, DocumentReference, getDoc } from "@firebase/firestore";
export default {
  name: "AffAccountProvider",
  props: {
    affAccountRef: {
      type: DocumentReference,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      affLinkData: null
    };
  },
  computed: {
    affAccountId() {
      return this.$_.get(this.affAccountRef, "id");
    },
    affAccountData() {
      return this.$store.getters["affiliate/affiliate"](this.affAccountId);
    },
    affLink() {
      return `${
        ["production"].includes(process.env.NODE_ENV)
          ? "https://fixed.net?aff="
          : location.origin + "/aff?id="
      }${this.$_.get(this.affAccountData, "affLinkRef.id")}`;
    }
  },
  async created() {
    if (!this.affAccountRef) return;
    try {
      await this.$store.dispatch("affiliate/observeAffiliate", {
        affAccountRef: this.affAccountRef
      });
      const affLinkDoc = await getDoc(this.affAccountData.affLinkRef);
      this.affLinkData = affLinkDoc.exists() ? affLinkDoc.data() : null;
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("affiliate/unobserveAffiliate", {
      affAccountId: this.affAccountId
    });
  },
  methods: {
    format(amount) {
      amount = isNaN(amount) ? 0 : amount;
      return `$${amount} USD`;
    },
    requestWithdrawal() {
      let balance = this.affAccountData.balanceAvailable;
      balance = isNaN(balance) ? 0 : balance;
      if (!balance)
        return this.$toast.open({
          message: "You have no available funds to withdraw",
          position: "is-bottom",
          type: "is-warning",
          queue: false
        });
      this.$store.dispatch("tasks/openAddTaskModal", {
        userId: this.$_.get(this.affAccountData, "userRef.id"),
        taskRef: doc(
          this.$firestore,
          "taskTypes",
          "affiliate-commission-withdrawl"
        ),
        default: `I'd like to withdraw my affiliate balance of ${this.format(
          balance
        )}.`
      });
    },
    providerData() {
      return {
        affAccountData: this.affAccountData,
        affLinkData: this.affLinkData,
        affLink: this.affLink
      };
    },
    providerMethods() {
      return {
        $format: this.format,
        $requestWithdrawal: this.requestWithdrawal
      };
    }
  },
  render(createElement) {
    if (this.isLoading) return createElement("loading", null);
    return (
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        ...this.providerData(),
        ...this.providerMethods()
      })
    );
  },
  provide() {
    return {
      affAccountProviderData: this.providerData,
      affAccountProviderMethods: this.providerMethods()
    };
  }
};
</script>
